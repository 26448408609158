import React from 'react';
// import roundlogo from "./resources/roundlogo.svg";
import { useSearchParams } from "react-router-dom";
import { ServerStatsTable } from "./ServerStatsTable";
// import { Navbar } from "./navbar";
import './App.css';
// import { URLS } from './URLS'

import { useEffect, useState } from "react";

const SERVERURL = "" //URLS



export default function ServerPage() {
  let [searchString, setSearchString] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  let [totalPages, setTotalpages] = useState(1)
  let [currentPage, setCurrentPage] = useState(1)
  let [totalRecords, setTotalRecords] = useState(10)
  let [statsData, setstatsData] = useState([])
  let [initialPage, setInitialPage] = useState(1)

  useEffect(() => {
    async function reload() {
      if (!searchString) {
        try {
          const requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };

          let page = searchParams.get("page")
          if (!page) {
            page = "1"
          }
          // setInitialPage(page)
          let response = await fetch(SERVERURL + "/api/server", requestOptions)
          let result = await response.json()
          // setTotalpages(result.pages)
          // setTotalRecords(result.totalRecords)
          setstatsData(result)
          return
        } catch (error) {
          console.log('error', error)
          return
        }
      }

      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        let response = await fetch(SERVERURL + "/player" + searchString, requestOptions)
        let result = await response.json()
        // setTotalpages(result.pages)
        // setTotalRecords(result.totalRecords)
        console.log(result)
        setstatsData(result)
      } catch (error) {
        console.log('error', error)
      }
    }
    reload()
  }, [searchString])

  // async function reload() {
  //   try {
  //     const requestOptions = {
  //       method: 'GET',
  //       redirect: 'follow'
  //     };

  //     let response = await fetch(SERVERURL + "/api/coins?page=" + currentPage + "&sorting=" + sortingState, requestOptions)
  //     let result = await response.json()
  //     setTotalpages(result.pages)
  //     setTotalRecords(result.totalRecords)
  //     setstatsData(result.coins)
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  async function handlePagination(nextPage, prevPage) {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      setSearchString("")
      let response = await fetch(SERVERURL + "/api/coins?page=" + nextPage + "&search=" + searchString, requestOptions)
      let result = await response.json()
      setTotalpages(result.pages)
      setTotalRecords(result.totalRecords)
      setstatsData(result.coins)
      setCurrentPage(nextPage)
    } catch (error) {
      console.log('error', error)
    }
  }
  return (
    <div className='min-h-screen bg-[url("bg.jpg")]'>
      {/* <Navbar handleSearch={(i) => { setSearchString(i) }} /> */}
      <div className="flex justify-center">
        {/* <img src={roundlogo} alt="" className="" /> */}
      </div>

      <div className="flex my-7 w-full justify-center">
        {/* <img src={hero} alt="" /> */}
        <span className="flex mt-10 bg-clip-text text-transparent text-5xl text-center font-[900] text-white p-1 select-none">DAYZ Scoreboard</span>
      </div>

      <div className="flex flex-col lg:w-full mt-10 ">
        <div className='flex justify-center w-full'>
          <div className='flex cursor-pointer w-full max-w-[170px] h-10 p-5 bg-black justify-center mb-10 items-center text-xl text-gray-400 hover:text-white font-bold rounded-xl'><a href="/player">Go to player</a></div>
        </div>
        <ServerStatsTable initialPage={initialPage} statsData={statsData} handlePagination={handlePagination} totalPages={totalPages} totalRecords={totalRecords} />
      </div>
    </div >
  );
}