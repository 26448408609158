import './App.css';
import React from 'react';
import { useState } from 'react';
import { StatsTableRow } from "./StatsTableRow";
import { Pagination } from './Pagination';

export function StatsTable(props) {
    let firstCoinRank = 0
    let lastCoinRank = 1
    for (let r = 0; r < props.statsData.length; r++) {
        if (firstCoinRank === 0) {
            firstCoinRank = props.statsData[r].rank
        }
        firstCoinRank = Math.min(firstCoinRank, props.statsData[r].rank)
        lastCoinRank = Math.max(lastCoinRank, props.statsData[r].rank)
    }
    return (
        <div className='flex flex-col lg:w-full overflow-x-scroll'>
            <div className='flex lg:w-full lg:justify-center'>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Rank</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[150px] max-w-[200px] py-3 text-xl cursor-pointer select-none">Username</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Kills</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Deaths</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">PVP_KD</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Headshots</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Longest Kill</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Kill Streak</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[100px] max-w-[150px] py-3 text-xl cursor-pointer select-none">Damage</div>
                <div className="flex text-white justify-center bg-[#000000] font-bold font-sans w-full min-w-[150px] max-w-[200px] py-3 text-xl cursor-pointer select-none">Environmental Deaths</div>
            </div>
            {props.statsData.map((singleStatData, i) =>
                <StatsTableRow singleStatData={singleStatData} key={i} i={i} />
            )
            }
            {/* <div className="flex justify-center mt-10">
                <Pagination initialPage={props.initialPage} handlePageChange={props.handlePagination} max={props.totalPages} totalRecords={props.totalRecords} firstCoinRank={firstCoinRank} lastCoinRank={lastCoinRank} />
            </div> */}
        </div>
    )
}