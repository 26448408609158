import './App.css';
import React from 'react';

export function ServerStatsTableRow(props) {
    console.log("test", props.singleStatData.servername)
    return (<div className="flex lg:w-full lg:justify-center group">
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.i + 1}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[150px] max-w-[200px] py-3 cursor-pointer select-none">{props.singleStatData.servername}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.total_kill}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.total_death}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.total_death ? Number((props.singleStatData.total_kill / props.singleStatData.total_death).toFixed(2)) : 0}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.total_headshot_kill}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{(props.singleStatData.longest_kill || 0) + " meteres"}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.kill_streak}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[100px] max-w-[150px] py-3 cursor-pointer select-none">{props.singleStatData.total_damage}</div>
        <div className="flex text-[#979696] group-hover:text-white justify-center bg-[#000000] font-sans w-full min-w-[150px] max-w-[200px] py-3 cursor-pointer select-none">{props.singleStatData.total_environment_death}</div>
    </div >)
}